import React from 'react';
import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

const ChurnOperationText = ({ numberOperation }) => {
  const textNumberOperation = numberOperation === 'first' ? 'primera' : 'próxima';
  return (
    <Box
      sx={{
        margin: { xs: 2, sm: 4, md: 6, lg: 10 },
        '& .common-text': {
          fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
          lineHeight: '1.3',
        },
        '& .bold-text': {
          fontWeight: 'bold',
          fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
          lineHeight: '1.3',
        },
        '& .highlight-text': {
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
        },
        '& .line-through-text': {
          textDecoration: 'line-through',
          textDecorationColor: '#E91E63',
          textDecorationThickness: '4px',
          marginLeft: '0.5rem',
          fontWeight: 'bold',
          fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
        },
        '& .large-text': {
          fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2.4rem' },
          marginLeft: { xs: 0, sm: '2rem', md: '4rem', lg: '9rem' },
        },
      }}
    >
      <Typography variant="h4" className="common-text">
        Para tu {textNumberOperation} operación:
      </Typography>
      <Typography variant="h4" className="bold-text">
        Adelanta hoy el{' '}
        <Typography variant="h4" component="span" className="highlight-text">
          pago de tus facturas
        </Typography>
      </Typography>
      <Typography variant="h4" className="bold-text">
        a tasa 0.99% en operaciones
      </Typography>
      <Typography variant="h4" className="bold-text" sx={{ display: 'flex', alignItems: 'center' }}>
        de hasta{' '}
        <Typography variant="h4" component="span" className="line-through-text">
          $10.000.000
        </Typography>
      </Typography>
      <Typography className="bold-text large-text">
        $20.000.000
      </Typography>
      <Box
        sx={{
          border: '2px solid #E91E63',
          padding: '1rem',
          margin: '1rem 0',
          borderRadius: '5px',
          maxWidth: '90%',
        }}
      >
        <Typography variant="subtitle" fontSize="0.5rem" component="span">
          Para hacer válido el descuento en tu primera operación,
          muestra el siguiente codigo al ejecutivo que te sera asignado: {' '}
        </Typography>
        <Typography variant="subtitle" component="span" color="primary" fontSize="0.5rem">
          FNG099
        </Typography>
      </Box>
    </Box>
  );
};

ChurnOperationText.propTypes = {
  numberOperation: PropTypes.string,
};

ChurnOperationText.defaultProps = {
  numberOperation: 'first',
};

export default ChurnOperationText;
